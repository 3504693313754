import { Routes } from "@angular/router";
import { AppComponent } from "../app/app.component"


export const rootRouterConfig: Routes = [
  {
    path: "",
    component: AppComponent,
    children: [
      
      {
        path: 'monitor/:branchId',
        loadChildren: () => import('./monitor/monitor.module').then(m => m.MonitorModule),
        data: { title: "Monitor", breadcrumb: "Monitor" }
      },
      {
        path: 'trainer-monitor/:branchId',
        loadChildren: () => import('./trainer-monitor/trainer-monitor.module').then(m => m.TrainerMonitorModule),
        data: { title: "Monitor", breadcrumb: "Monitor" }
      },
    ]
  },

];